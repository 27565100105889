@import '../../scss/imports';

.top-action-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  padding: 1.5rem 0;
  z-index: 1;

  .date-controls {
    align-items: center;
    display: flex;
    gap: .25rem;

    h4 {
      display: inline-block;
      font-size: 2rem;
      margin: 0;
    }

    .date-nav,
    h4 {
      color: $secondary-text-color;
    }
  }

  .action-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    justify-content: flex-end;
  }
}
