@import '../../scss/imports';

#permission-group-dialog {
  border-radius: .75rem;
  box-shadow: 0 .375rem 2rem 0 rgba(130, 141, 168, .2);
  color: #121212;
  width: 32rem;

  .dialog-title {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0 0 1.75rem;
  }

  .form-field-dropdown {
    margin-bottom: 2rem;

    .select__single-value { 
      color: rgba(130, 130, 130, 1);
    }

    .select__control {
      background: rgba(138, 171, 205, .1);
      border-color: transparent;
      border-radius: .315rem;
      font-family: $font-regular;
      font-size: .875rem;
      font-weight: 600;
      padding-left: .3125rem;
    }

    .select__single-value {
      font-weight: 600;
    }

    .select__indicator {
      margin-right: .25rem;
    }

    .select__indicator-separator {
      display: none;
    }
  }

  button {
    width: 13.75rem;
  }

  .cancel-button {
    background: rgba(0, 0, 0, .05);
    margin-right: 1.5rem;
  }
}
