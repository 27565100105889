@use 'variables' as theme;
@use '@react-md/theme/dist/color-palette' as *;
@forward 'react-md' with
  (
    $rmd-theme-light-background: theme.$body-bg-color,

    // Theme colors
    $rmd-theme-light: true,
    $rmd-theme-primary: theme.$primary-color,
    $rmd-theme-secondary: theme.$secondary-color,
    $rmd-theme-warning: theme.$warning-color,
    $rmd-theme-on-warning: theme.$warning-text-color,

    // Buttons
    $rmd-button-text-border-radius: .1875rem,
    $rmd-typography-font-family: theme.$font-regular,
    $rmd-button-contained-resting-elevation: 0,

    // States
    $rmd-states-focus-shadow-color: lighten(theme.$primary-color, 5%),

    // Forms
    $rmd-form-active-color: theme.$primary-color,
    $rmd-text-field-border-radius: theme.$border-radius,
    $rmd-switch-track-background-color: rgba(theme.$black, .2),
    $rmd-switch-track-height: 1.875rem,
    $rmd-switch-track-width: 3.125rem,
    $rmd-switch-track-border-radius: 2.1875rem,
    $rmd-switch-ball-size: 1.625rem,
    $rmd-switch-ball-offset: .9rem,
    $rmd-switch-container-horizontal-padding: 0,
    $rmd-switch-container-vertical-padding: 0,

    // App bar
    $rmd-app-bar-height: 4rem,

    // Typography
    $rmd-typography-headline-1-styles: theme.$custom-typography-styles,
    $rmd-typography-headline-2-styles: theme.$custom-typography-styles,
    $rmd-typography-headline-3-styles: theme.$custom-typography-styles,
    $rmd-typography-headline-4-styles: theme.$custom-typography-styles,
    $rmd-typography-headline-5-styles: theme.$custom-typography-styles,
    $rmd-typography-headline-6-styles: theme.$custom-typography-styles,
    $rmd-typography-subtitle-1-styles: theme.$custom-typography-styles,
    $rmd-typography-subtitle-2-styles: theme.$custom-typography-styles,
    $rmd-typography-body-1-styles: theme.$custom-typography-styles,
    $rmd-typography-body-2-styles: theme.$custom-typography-styles,
    $rmd-typography-button-styles: theme.$custom-typography-styles,
    $rmd-typography-caption-styles: theme.$custom-typography-styles,
    $rmd-typography-overline-styles: theme.$custom-typography-styles
  );
