// APP COLORS
$black: #000;
$white: #fff;
$tertiary-color: #919191;
$tertiary-text-color: $black;
$warning-color: #f44336;
$warning-text-color: $white;
$text-color-light: rgba($black, .6);
$light-gray: #757575;
$dark-gray: #474747;
$primary-blue: #0075be;
$primary-color: #2196f3;
$primary-color-dark: #1976d2;
$secondary-color: #dedede;
$priority-color: #f11a1a;
$failed-color: #ef5350;
$success-color: #00c853;
$action-color: $secondary-color;
$active-color: #eb5757;
$label-color: #878787;
$disabled-color: #c3c3c3;
$text-color: #2c2c2c;
$secondary-text-color: rgba(#000, .8);
$secondary-button-text-color: $text-color;
$title-color: rgba(#000, .8);
$accent-color: #a8a8a8;
$body-bg-color: #fafafa;
$header-background-color: #fff;
$header-action-button-color: $accent-color;
$light-text-color: rgba($white, .8);
$input-bg-color: #fff;
$border: 1px solid $secondary-text-color;
$disabled-input-bg-color: #f0f0f0;

// TEXTURES
$box-shadow: 0 .25rem 16px rgba(#000, .1);
$border-radius: .375rem;
$body-bg-color: #f4f4f4;

// FONTS
$ph-font-path: 'assets/fonts';
$font-ultra-light: 'AvenirNextLTW01-UltraLi', Arial, sans-serif;
$font-light: 'Avenir Next W01 Light', Arial, sans-serif;
$font-regular: 'AvenirNextLTW01-Regular', Arial, sans-serif;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial, sans-serif;
$font-demi: 'Avenir Next LT W01 Demi', Arial, sans-serif;
$font-heavy: 'AvenirNextLTW01-Heavy', Arial, sans-serif;
$font-medium: 'Avenir Next W1G Medium', Arial, sans-serif;
$font-next: 'Avenir Next W1G', Arial, sans-serif;

$custom-typography-styles: (
  letter-spacing: normal,
);

// SCREEN SIZES
$xxs: em(400);
$xs: em(480);
$sm: em(600);
$smt: em(800);
$md: em(960);
$lg: em(1280);
$xl: em(1440);
$ipad: em(768);
$landscape: em(1024);

$wrapper: 64.5rem;

// PH OVERRIDES
$ph-header-action-button-color: #a8a8a8;
$ph-header-background-color: #fff;
