@import '../../scss/imports';

.search-input-container {
  position: relative;

  .input {
    border: 1px solid #e0e0e0;
    border-radius: 1rem;
    font-family: $font-regular;
    font-size: .875rem;
    height: 2rem;
    line-height: 2rem;
    min-width: 100%;
    padding-left: 2.625rem;
  
    &:focus {
      outline-color: $primary-color;
    }
  }
  
  .search-icon {
    --rmd-icon-color: rgba(0, 0, 0, .6);
    --rmd-icon-size: 1.5rem;
    height: 1.5rem;
    left: .5rem;
    position: absolute;
    top: .25rem;
    width: 1.5rem;
  }
  
  .spinner {
    position: absolute;
    right: .375rem;
    top: .25rem;
  }
}
