@import '../../scss/variables';

#send-registration-dialog {
  border-radius: .75rem;
  box-shadow: 0 .375rem 2rem 0 rgba(130, 141, 168, .2);
  width: 32rem;
  
  .dialog-title {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0 0 1.75rem;
  }

  button {
    width: 13.75rem;
  }

  .cancel-button {
    background: rgba(0, 0, 0, .05);
    margin-right: 1.5rem;
  }
}
