@import '../../scss/imports';

.wrapper {
  position: relative;

  &.error {
    .leftChild,
    .rightChild,
    .label,
    .input {
      color: $priority-color !important;
    }

    .input {
      border-color: $priority-color;
    }
  }
}

.label {
  color: $dark-gray;
  display: block;
  font-family: $font-medium;
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: .5rem;
}

.input {
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: .25rem;
  color: #000;
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1rem;
  padding: .25rem 1rem;
  width: 100%;

  &:disabled {
    background-color: #222;
    color: $secondary-text-color;
  }

  &::placeholder {
    color: rgba(0, 0, 0, .4);
    line-height: 1rem;
  }
}

.textArea {
  min-height: 6.5rem;
  padding: .75rem 1rem;
  resize: vertical;
}

.inputWrapper {
  position: relative;
}

.leftChild,
.rightChild {
  --ph-font-icon-color: #5d5d5d;
  color: #5d5d5d;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.togglePasswordButton {
  cursor: pointer;
  transition: color .2s ease-in-out;

  &:hover {
    color: #1a1919;
  }
}

.rightChild {
  right: .75rem;
}

.buttonIcon {
  color: inherit;
}

.leftChild {
  left: .5rem;
}

.withRightChild .input {
  padding-right: 2.75rem;
}

.withLeftChild .input {
  padding-left: 2.25rem;
}

.toggle {
  --ph-font-icon-size: 1.5rem;
  --ph-font-icon-color: #bebebe !important;
  right: 0;

  &:disabled {
    color: #222 !important;
  }
}

.dialog {
  background: #fff;
  border-radius: $border-radius;
  box-shadow: 0 .0625rem .625rem rgba(0, 0, 0, .04), 0 .25rem .3125rem rgba(0, 0, 0, .06), 0 .125rem .25rem -.0625rem rgba(0, 0, 0, .09);
  z-index: 9;
}

.timeWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 .5rem;
}

.timeLabel {
  color: $text-color;
  font-family: $font-next;
  font-size: 1rem;
  font-weight: 700;
}

.timeInput {
  margin: 0;
  width: 55%;

  .input {
    padding: .25rem 1rem;
  }
}

.errorMessage,
.helpText {
  color: $priority-color;
  display: inline-block;
  font-size: .75rem;
  line-height: 1rem;
  margin-top: .25rem;
  width: 100%;
}

.errorMessage,
.errorIcon {
  color: $priority-color;
}

.errorMessage {
  display: grid;
  gap: .25rem;
  grid-template-columns: auto 1fr;
}

.errorIcon {
  font-size: .75rem;
}

.helpText {
  color: $secondary-text-color;
}

input[type='time'],
input[type='datetime-local'] {
  &::-webkit-calendar-picker-indicator {
    filter: contrast(0%);
    height: 1.25rem;
    width: 1.25rem;
  }
}
