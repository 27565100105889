@import '../../scss/imports';
$filter-group-border-radius: .5rem;

.filter-group {
  font-size: .875rem;
}

.filter-group-button {
  align-items: center;
  background-color: #0000000d;
  border-radius: 1rem;
  color: $black;
  cursor: pointer;
  display: flex;
  font-family: $font-regular;
  gap: .25rem;
  height: 2rem;
  justify-content: space-between;
  padding: 0 .5rem 0 1rem;

  &.filter-group-button-active {
    background-color: $primary-color;
    color: #fff;

    .ph-font-icon {
      color: #333;
    }
  }
}

.filter-group-button-label-container {
  display: flex;
  gap: .25rem;
  line-height: 2rem;
  text-transform: capitalize;
  white-space: nowrap;

  .filter-text {
    display: inline-flex;
  }

  .filter-count {
    display: inline-flex;
    justify-content: center;
    min-width: 1.75rem;
  }
}

.filter-group-button-action {
  align-items: center;
  display: inline-flex;
  height: 1.5rem;
  width: 1.5rem;
}

.filter-group-popover {
  background: #fff;
  border-radius: $filter-group-border-radius;
  box-shadow: 0 .25rem 1rem rgba(#828da8, .2);
  z-index: 2;
}

%header {
  align-items: center;
  border-radius: $filter-group-border-radius $filter-group-border-radius 0 0;
  display: flex;
  font-family: $font-demi;
  padding: .5rem .75rem;
}

.filter-group-menu-header {
  @extend %header;
  background-color: $primary-color;
  color: #fff;
  justify-content: space-between;

  .ph-font-icon {
    color: $white;
  }
}

.filter-group-item-header {
  @extend %header;
  background-color: #fff;
  color: $secondary-text-color;
  gap: .25rem;
}

.filter-group-content {
  min-width: 16.25rem;
  
  ul.filter-group-list {
    height: 5.75rem;
    margin: 0 !important;
  }
}

.filter-group-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: (100%);
}

.filter-group-list-item {
  background: #fff;
  padding: 0 .75rem;
  transition: background .2s ease-in-out;
  width: 100%;

  &:hover {
    background: #f7f7f7;
  }
}

.filter-group-list-item-disabled {
  opacity: .5;
  pointer-events: none;
}

.filter-group-list-item-content {
  align-items: center;
  border-bottom: .125rem solid #f7f7f7;
  cursor: pointer;
  display: flex;
  padding: .625rem 0;

  .check-icon {
    color: $primary-color;
  }

  .navigate-icon {
    color: #b2b2b2;
  }
}

.filter-group-list-item-label,
.filter-group-list-item-icon-left,
.filter-group-list-item-icon-right {
  height: 1.5rem;
  line-height: 1.5rem;
}

.filter-group-list-item-label {
  color: #0009;
  flex-grow: 1;
  font-family: $font-demi;
  margin: 0 .25rem;
}

.filter-group-action-container {
  padding: .75rem;
}
