@use 'variables' as theme;
@use './everything' as *;
@include react-md-utils;

.rmd-button {
  font-family: $font-demi;
}

.rmd-button--primary {
  @include rmd-theme-update-var(on-primary, $white);
}

.rmd-button--secondary {
  @include rmd-theme-update-var(on-secondary, $white);
}

.ph-checkbox {
  @include rmd-button-theme-update-var(icon-size, 1.5rem);
  @include rmd-icon-theme-update-var(color, $primary-color);
  @include rmd-theme-update-var(secondary, $primary-color);
  @include rmd-theme-update-var(text-secondary-on-background, $primary-color);

  height: 2.5rem;

  label {
    color: #000;
    cursor: pointer;
    flex-grow: 1;
    font-size: .875rem;
  }

  &.top-control {
    label {
      font-family: $font-demi;
    }
  }

  &.secondary {
    @include rmd-icon-theme-update-var(color, $secondary-color);
    @include rmd-theme-update-var(secondary, $secondary-color);
    @include rmd-theme-update-var(text-secondary-on-background, #646464);
  }
}

.rmd-expansion-panel__icon {
  .rmd-icon-rotator {
    background-color: rgba(#000, .05);
    border-radius: 50%;
  }
}
