@import '../../scss/imports';

#create-permission-group-dialog {
  border-radius: .75rem;
  width: 37.5rem;

  header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;

    .fill {
      width: 3rem;
    }
    
    h1 {
      font-family: $font-regular;
      font-size: 2rem;
      font-weight: 700;
      margin: 0;
    }

    i {
      color: $black;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .dialog-content {
    padding-bottom: .25rem;

    .group-name-container .error {
      border: 1px solid $warning-color;
    }

    .rmd-text-field-container {
      background: rgba(138, 171, 205, .1);
      height: 1.875rem;
      margin: .5rem 0;
    }
  
    .rmd-text-field,
    .rmd-text-field-container {
      border-color: transparent;
      border-radius: .4375rem;
      font-size: .875rem;

      &.rmd-text-field-container--outline-error {
        border-color: $warning-color;
      }
    }
  
    .rmd-text-field-addon:first-child {
      color: rgba(150, 150, 150, 1);
      top: .375rem;
    }
  
    .rmd-label {
      font-size: .875rem;
    }
  
    .rmd-text-field,
    .rmd-text-field-container,
    .rmd-select__label {
      font-weight: 600;
      height: 2.375rem;
      line-height: .5rem;
    }
  
    .rmd-floating-label {
      width: 100%;
    }
  
    .rmd-text-field-addon i {
      color: $disabled-color;
      font-size: 1.125rem;
      padding-top: .25rem;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 700;
      margin: 0 0 .75rem;
    }

    .permissions-container {
      .permissions-column-header {
        color: #2d3843;
        font-size: 1.125rem;
        font-weight: 700;
        margin: 1.25rem .25rem .75rem 0;
        text-align: right;
      }

      h3 {
        margin: 1.5rem 0 2rem;
        text-align: center;
      }

      .permissions-options-container {
        padding-right: .375rem;
      }

      .permission-row {
        align-items: center;
        display: flex;
        height: 1.5rem;
        justify-content: space-between;
        margin: .375rem 0 0 .5rem;

        .permission-label {
          text-transform: capitalize;
          width: calc(100% - 9.375rem);

          &.analytics_reconciliation,
          &.analytics_other {
            border-left: 1px solid $tertiary-color;
            margin-left: 1rem;
            padding-left: 1rem;
          }

          &.analytics_cash {
            border-left: 1px solid $tertiary-color;
            margin-left: 2rem;
            padding-left: 1rem;
          }
        }

        .rmd-toggle {
          border-radius: 0;
          height: 1.5rem;
          margin: .5rem;
          width: 1.5rem;
        }

        .rmd-toggle--disabled>.rmd-toggle__icon {
          color: #bebebe;
        }

        &.icon-distinct {
          i {
            color: $primary-color;
          }
        }
      }
    }
  }

  .suite-top-level,
  .users-top-level,
  .persons-top-level {
    border-left: 1px solid #d6d6d6;
    margin-left: .75rem;
    padding-left: .75rem;
  }

  footer {
    box-shadow: 0 -1.75rem 2rem -1.8125rem rgba(0, 0, 0, .05);
    margin: 0 1.5rem;
    padding: 1.5rem 0;
    
    button {
      margin: auto;
    }
  }

  .orgs-text-input {
    color: #777;
    font-family: $tertiary-color;
  }
}
