@import '../../scss/variables';

.table-container {
  overflow-y: auto;
  position: relative;
}

.table {
  border-collapse: separate;
  border-spacing: 0 .5rem;
  font-size: .875rem;
  position: relative;
  table-layout: fixed;
  text-align: left;
  width: 100%;

  td,
  th {
    padding: 0 .5rem;

    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }

  th {
    padding: .5rem;
    position: sticky;
    top: .5rem;
  }

  td {
    line-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.table-row-body {
  background-color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  td {
    &:first-child {
      border-radius: .5rem 0 0 .5rem;
    }

    &:last-child {
      border-radius: 0 .5rem .5rem 0;
    }
  }

  .action {
    --rmd-icon-size: 1.5rem;
    --rmd-button-icon-size: 1.5rem;
    --rmd-button-color: #{rgba(#000, .2)};
    margin-left: .75rem;
    text-overflow: initial;

    transform: translate(0, .125rem);
  }

  &.disabled {
    background: rgba($white, .5) !important;
    color: #444;
  }  
}

.th-item {
  align-items: center;
  display: inline-flex;
  gap: .5rem;

  &.clickable {
    cursor: pointer;
  }

  .th-icon {
    min-width: .875rem;
    width: .875rem;
  }

  .rmd-menu {
    border-radius: 1.5rem;
  }
}
