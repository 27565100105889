@import '../../scss/variables';

.progress-container {
  height: .25rem;
}

.persons-container {
  --rmd-theme-secondary: #2186f3;

  ul {
    align-items: center;
    background: $white;
    border-radius: .25rem;
    display: flex;
    font-size: .875rem;
    height: 2.5rem;
    justify-content: space-between;
    list-style: none;
    margin: .5rem 0;
    overflow-anchor: none;
    padding: 0;

    .user-checkbox {
      width: 2.75rem;
    }

    .user-display-name,
    .user-email,
    .user-permission-group,
    .user-status {
      overflow: hidden;
      padding-right: 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user-display-name,
    .user-permission-group,
    .user-status {
      text-transform: capitalize;
    }

    .user-display-name {
      padding-left: .5rem;
    }

    .user-display-name,
    .user-email {
      max-width: calc(28% - 1.4375rem);
      min-width: calc(28% - 1.4375rem);
    }

    .user-permission-group,
    .user-status {
      max-width: calc(22% - 1.4375rem);
      min-width: calc(22% - 1.4375rem);
    }

    .user-action {
      button {
        border-radius: .875rem;
        height: 1.75rem;
        margin-right: .5rem;
        padding: 2px 0 0 2px;
        width: 1.75rem;

        &:hover {
          background: #f5f5f5;
        }
      }
    }

    &.deactivated {
      background: rgba($white, .5);
      color: #444;

      .deactivated-checkbox {
        opacity: 0;
      }
    }
  }

  .data-alert {
    margin-top: 4rem;
    text-align: center;
  }

  .user-list-headers {
    align-items: center;
    display: flex;
    font-family: $font-demi;
    height: 2.75rem;
    margin: 0 .25rem;
    width: 100%;

    .users-header-select-all {
      margin-top: .125rem;
      transform: translate(-.25rem, 0);
      width: 2.75rem;
    }

    .users-header-name {
      padding: 0 1rem 0 .5rem;
    }

    .users-header-name,
    .users-header-email {
      max-width: calc(28% - 1.4375rem);
      min-width: calc(28% - 1.4375rem);
    }

    .users-header-permission_group,
    .users-header-status {
      max-width: calc(22% - 1.4375rem);
      min-width: calc(22% - 1.4375rem);
    }

    .users-header-name,
    .users-header-email,
    .users-header-permission_group,
    .users-header-status {
      span {
        margin-right: .375rem;
      }

      button {
        align-items: center;
        display: flex;

        svg {
          min-width: .875rem;
          width: .875rem;
        }
      }
    }

    .user-header-action {
      padding-right: .625rem;
      text-align: right;
      width: 3rem;
    }
  }

  .load-more-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }
}

.users-footer {
  background: $white;
  border-top: 1px solid #e0e0e0;
  bottom: -4rem;
  position: fixed;
  width: 100%;
  z-index: 3;

  &.display-footer {
    bottom: 0;
    transition: bottom .15s linear;
  }
  
  .footer-content {
    align-items: center;
    display: flex;
    height: 4rem;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 64.5rem;
  }

  .rmd-button {
    background: $primary-color;
    color: $white;
    padding: 0 1rem;
    
    i {
      scale: 1.5;
    }
  }

  .clear-selection {
    background: rgba(0, 0, 0, .05);
    color: $black;
    margin-right: 1.5rem;
  }
}

#user-edit-actions-menu {
  border: 1px solid #e1e1e1;
  border-radius: .5rem;
  box-shadow: 0 .25rem 1.5rem 0 rgba(0, 0, 0, .15);
  transform: translate(1.5rem, -3.625rem);

  .rmd-menu-item {
    font-size: .875rem;
    padding: .5rem 1.5rem;

    i {
      color: #727272;
      margin-right: .75rem;
    }
  }
}

#reactivate-dialog {
  border-radius: .75rem;
  box-shadow: 0 .375rem 2rem 0 rgba(130, 141, 168, .2);
  width: 32rem;
  
  .dialog-title {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0 0 1.75rem;
  }

  button {
    width: 13.75rem;
  }

  .cancel-button {
    background: rgba(0, 0, 0, .05);
    margin-right: 1.5rem;
  }
}
