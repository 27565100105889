@import '../../scss/imports';

#user-modal {
  border-radius: .75rem;
  color: #2d3843;
  width: 37.5rem;

  header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;

    .fill {
      width: 3rem;
    }
    
    h1 {
      font-family: $font-regular;
      font-size: 2rem;
      font-weight: 700;
      margin: 0;
    }

    i {
      color: $black;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .user-dialog-content {
    padding-bottom: 0;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 1.75rem 0 .75rem;

    &:first-of-type {
      margin: 0 0 .5rem;
    }
  }

  .permission-description {
    margin: 0 0 1.25rem;
  }

  .rmd-floating-label {
    display: none;
  }

  .rmd-floating-label--active {
    border-radius: .75rem;
    padding: 1rem;
    transform: none;
  }

  .rmd-text-field,
  .rmd-text-field-container {
    border-color: transparent;
    border-radius: .3125rem;
    font-size: .875rem;
    
    &.rmd-text-field-container--outline-error {
      border-color: $warning-color;
    }
  }
  
  .form-field-dropdown {
    margin-top: 1rem;

    .select__single-value { 
      color: rgba(130, 130, 130, 1);
    }

    .select__control {
      background: rgba(138, 171, 205, .1);
      border-color: transparent;
      border-radius: .315rem;
      font-family: $font-regular;
      font-size: .875rem;
      font-weight: 600;
      padding-left: .3125rem;
    }

    .select__single-value {
      font-weight: 600;
    }

    .select__indicator {
      margin-right: .25rem;
    }

    .select__indicator-separator {
      display: none;
    }
  }

  .org-dropdown {
    .select__control {
      svg {
        color: rgba(130, 130, 130, 1);
      }
    }

    .select__multi-value {
      margin-left: 0;
    }

    .select__multi-value__remove {
      padding: 0 .375rem;
    }
  }

  .rmd-text-field-container {
    background: rgba(138, 171, 205, .1);
    color: rgba(130, 130, 130, 1);
    height: 1.875rem;
    margin: .5rem 0;
  }

  .rmd-label {
    font-size: .875rem;
  }

  .rmd-text-field,
  .rmd-text-field-container,
  .rmd-select__label {
    font-weight: 600;
    height: 2.375rem;
    line-height: .5rem;
  }

  .rmd-floating-label {
    width: 100%;
  }

  .rmd-text-field-addon i {
    color: $disabled-color;
    font-size: 1.125rem;
    padding-top: .25rem;
  }

  .form-name-row {
    display: flex;
    justify-content: space-between;

    .rmd-text-field-container {
      width: 48%;
    }
  }

  .organization-select {
    margin-top: 1rem;
  }

  .permissions-graphic-container {
    padding: .5rem .75rem .75rem .5rem;
    
    .permissions-column-header {
      font-size: 1.125rem;
      font-weight: 700;
      margin: 1.25rem 0 .75rem;
      text-align: right;
    }

    .suite-top-level,
    .users-top-level,
    .persons-top-level {
      border-left: 1px solid #d6d6d6;
      margin-left: .75rem;
      padding-left: .75rem;
    }

    .permission-row {
      align-items: center;
      display: flex;
      height: 1.5rem;
      justify-content: space-between;
      margin-top: .5rem;
      text-transform: capitalize;
      width: 100%;

      .permission-label {
        width: calc(100% - 9rem);
      }
  
      .permission-scope {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        width: 4.5rem;
  
        i {
          color: #2d9cdb;
          font-size: 1.25rem;
          margin-right: .25rem;
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    margin: 0 1.5rem;
    padding: 1rem 0;

    .user-actions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      button {
        border-radius: .25rem;
        width: calc(50% - 1rem);
        
        i {
          margin-right: .5rem;
        }
      }
    }
    
    button {
      margin: .5rem auto;
      width: 11.25rem;
    }

    &.permissions-overflow {
      box-shadow: 0 -1.75rem 2rem -1.8125rem rgba(0, 0, 0, .05);
    }
  }
}
